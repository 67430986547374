<template>
    <div id="main">
        <div class="header">
            <div class="box">
                <div class="header_txt">考试须知</div>
                <el-breadcrumb separator="/">
                    <el-breadcrumb-item :to="{ path: '/' }">
                        考试列表
                    </el-breadcrumb-item>
                    <el-breadcrumb-item style="color: #56327a">
                        查看须知
                    </el-breadcrumb-item>
                </el-breadcrumb>
            </div>
        </div>
        <el-main>
            <div style="width: 100%">
                <div class="section">
                    <div class="header-top">
                        <el-tooltip
                                    v-if="examInfo.visible_type == 1"
                                    effect="dark"
                                    :content="examInfo.visible_tips"
                                    placement="top">
                            <el-button
                                    class="btn_1"
                                    v-if="examInfo.exam_status == 3 &&
                                    (examInfo.type == 4||examInfo.type == 6||examInfo.type == 7) &&
                                    ((examInfo.status >= 0 && examInfo.status < 6) ||
                                    examInfo.status == 8 || examInfo.status == 7 ||
                                    examInfo.status == 99)"
                                    :disabled="isDisable"
                            >立即报名
                            </el-button>
                        </el-tooltip>
                        <el-button
                                class="btn_2"
                                @click="toidentity"
                                v-if="examInfo.exam_status == 3 &&
                                (examInfo.type == 4||examInfo.type == 6||examInfo.type == 7) &&
                                ((examInfo.status >= 0 && examInfo.status < 6) ||
                                examInfo.status == 8 || examInfo.status == 7 ||
                                examInfo.status == 99) && examInfo.visible_type != 1"
                                :disabled="examInfo.exam_status < 3 || isDisable"
                        >立即报名
                        </el-button>
                    </div>
                    <div class="textBox">
                        <div class="handle">{{ examInfo.name }}考试须知</div>
                        <div class="headBox">{{ examInfo.desc }}</div>
                        <div class="mainText" v-html="examInfo.content"></div>
                        <!--2023 TACA-->
                        <!--<div class="mainText">
                            <div>
                                <div class="mainText-son">
                                    <div class="mainText-son-list">
                                        学科能力综合测试(THUSSAT Academic Comprehensive Assessment/TACA)是面向全国各省份高一和高二年级学生，及特别优秀的初三及高三年级学生的水平测试，采取统一知识框架及考试结构的考查方式，旨在识别学科综合能力优秀的学生。<br/>
                                        学科能力综合测试(TACA)是THUSSAT配合教育改革和高校人才选拔在2021年发布的全新高严肃性考试序列：<br/>
                                        考试是面向全国各省份高一和高二年级学生，及特别优秀的初三及高三年级学生的水平测试。采取统一知识框架及考试结构的考查方式，旨在识别学科综合能力优秀的学生；<br/>
                                        考查内容结合中学基础知识和大学选材视角；<br/>
                                        考试面向全国所有省份及城市，考生个人自主在网站上完成报名，中学报名请先与THUSSAT项目组取得联系；<br/>
                                        考试成绩可申请认证成绩单，用于向采信本成绩单的项目提交。
                                    </div>
                                </div>
                                <div class="mainText-son">
                                    <div class="mainText-head">
                                        一、面向群体
                                    </div>
                                    <div class="mainText-son-list">
                                        全国各省份高一和高二年级学生，及特别优秀的初三及高三年级学生。
                                    </div>
                                </div>
                                <div class="mainText-son">
                                    <div class="mainText-head">
                                        二、报名及考试时间安排
                                    </div>
                                    <div class="tablearea">
                                        <table cellspacing="0">
                                            <thead>
                                            <tr>
                                                <th>考试流程</th>
                                                <th>时间安排</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr>
                                                <td class="td_center">报名时间</td>
                                                <td>3月20日10:00-4月15日24:00</td>
                                            </tr>
                                            <tr>
                                                <td class="td_center">下载准考证时间</td>
                                                <td>4月26日10:00-4月28日24:00</td>
                                            </tr>
                                            <tr>
                                                <td rowspan="4" class="td_center">考试日</td>
                                                <td>中英文阅读：4月29日 8:30-10:30</td>
                                            </tr>
                                            <tr>
                                                <td>数学一：4月29日 10:30-12:00</td>
                                            </tr>
                                            <tr>
                                                <td>物理：4月29日 13:30-15:30</td>
                                            </tr>
                                            <tr>
                                                <td>数学二（选考）：4月29日 15:45-17:45</td>
                                            </tr>
                                            <tr>
                                                <td class="td_center">成绩查询时间</td>
                                                <td>5月1日10:00开放查询</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="mainText-son">
                                        <div class="mainText-son-list">
                                            *注：以上时间均指北京时间。
                                        </div>
                                    </div>
                                </div>
                                <div class="mainText-son">
                                    <div class="mainText-head">
                                        三、考试形式
                                    </div>
                                    <div class="mainText-son-list">
                                        采用“线上机考”模式，考生在指定考点、考场使用考试专用软件完成测试。
                                    </div>
                                </div>
                                <div class="mainText-son">
                                    <div class="mainText-head">
                                        四、成绩用途
                                    </div>
                                    <div class="mainText-son-list">
                                        参试考生可申请认证成绩单 (认证成绩单开放申请时间请留意后续信息发布)。部分其他采信认证成绩单的项目：
                                        ①清华大学丘成桐数学科学领军人才培养计划
                                        ②昆山杜克大学综合评价招生
                                        ③香港中文大学（深圳）综合评价招生
                                    </div>
                                </div>
                            </div>
                        </div>-->
                        <!--<div class="mainText">
                            <div>
                                <div class="mainText-son">
                                    <div class="mainText-son-list">
                                        学科能力综合测试(THUSSAT Academic Comprehensive Assessment/TACA)面向全国各省份高一和高二年级学生，及特别优秀的初三及高三年级学生的水平测试，采取统一知识框架及考试结构的考查方式，旨在识别学科综合能力优秀的学生。
                                        学科能力综合测试(TACA)是THUSSAT配合教育改革和高校人才选拔在2022年发布的全新高严肃性考试序列，与诊断性测试(TDA)和基础性测试(TGA)有较大不同：<br/>
                                        考试面向全国各省份高一和高二年级学生，及特别优秀的初三及高三年级学生的水平测试；<br/>
                                        采取统一知识框架及考试结构的考查方式，旨在识别学科综合能力优秀的学生；<br/>
                                        考查内容结合中学基础知识和大学选材视角，面向全国所有省份及城市，考生个人自主在网站上完成报名，中学报名请先与THUSSAT项目组取得联系；<br/>
                                        考试成绩可申请认证成绩单，用于向采信本成绩单的项目提交。
                                    </div>
                                </div>
                                <div class="mainText-son">
                                    <div class="mainText-head">
                                        一、面向群体
                                    </div>
                                    <div class="mainText-son-list">
                                        全国各省份高一和高二年级学生，及特别优秀的初三及高三年级学生。
                                    </div>
                                </div>
                                <div class="mainText-son">
                                    <div class="mainText-head">
                                        二、报名及考试时间安排
                                    </div>
                                    <div class="tablearea">
                                        <table cellspacing="0">
                                            <thead>
                                            <tr>
                                                <th>考试流程</th>
                                                <th>时间安排</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr>
                                                <td class="td_center">报名时间</td>
                                                <td>4月23日10:00-5月07日24:00</td>
                                            </tr>
                                            <tr>
                                                <td class="td_center">下载准考证时间</td>
                                                <td>5月18日10:00-5月20日24:00</td>
                                            </tr>
                                            <tr>
                                                <td rowspan="3" class="td_center">考试日</td>
                                                <td>中英文阅读：5月21日 9:00-12:00</td>
                                            </tr>
                                            <tr>
                                                <td>物理：5月21日 13:30-15:00</td>
                                            </tr>
                                            <tr>
                                                <td>数学：5月21日 15:15-17:15</td>
                                            </tr>
                                            <tr>
                                                <td class="td_center">成绩查询时间</td>
                                                <td>5月23日10:00后</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="mainText-son">
                                        <div class="mainText-son-list">
                                            *注：以上时间均指北京时间。
                                        </div>
                                    </div>
                                </div>
                                <div class="mainText-son">
                                    <div class="mainText-head">
                                        三、考试形式
                                    </div>
                                    <div class="mainText-son-list">
                                        采用“线上机考”模式，考生在指定考点机房使用考试专用软件完成考试。
                                    </div>
                                </div>
                                <div class="mainText-son">
                                    <div class="mainText-head">
                                        四、成绩用途
                                    </div>
                                    <div class="mainText-son-list">
                                        参试考生可申请认证成绩单 (认证成绩单开放申请时间请留意后续信息发布)。部分其他采信认证成绩单的项目：
                                        ①清华大学丘成桐数学科学领军人才培养计划
                                        ②昆山杜克大学综合评价招生
                                        ③香港中文大学（深圳）综合评价招生
                                    </div>
                                </div>
                            </div>
                        </div>-->
                        <!--<div class="headBox">
                            学科能力综合测试(THUSSAT Academic Comprehensive Assessment/TACA)是面向全国各省份高一和高二年级学生，及特别优秀的初三及高三年级学生的水平测试，采取统一知识框架及考试结构的考查方式，旨在识别学科综合能力优秀的学生。
                        </div>
                        <div class="mainText">
                            <div>
                                <div class="mainText-son">
                                    <div class="mainText-son-list">
                                        学科能力综合测试(TACA)是THUSSAT配合教育改革和高校人才选拔在2021年发布的全新高严肃性考试序列：
                                    </div>
                                    <div class="mainText-son-list">
                                        1、面向从初三到高三年级的学生，采用统一试卷进行水平测试，在全国范围内重点发掘具备学科特长的优秀学生。
                                    </div>
                                    <div class="mainText-son-list">
                                        2、考查内容兼顾中学基础知识和大学选材视角，全面考察学生学科综合能力。
                                    </div>
                                    <div class="mainText-son-list">
                                        3、面向全国所有省份及城市，考生个人自主在网站上完成报名，如中学组织统一报名请与考试项目组取得联系。
                                    </div>
                                    <div class="mainText-son-list">
                                        4、考试成绩可申请认证成绩单，用于向求真书院等采信本成绩单的部门提交。
                                    </div>
                                    <div class="mainText-son-list">
                                        2021年8月TACA综合测试分为：报名、准考证打印、考试、成绩单获取几个环节，由考试项目组统一组织并实施。
                                    </div>
                                </div>
                                <div class="mainText-son">
                                    <div class="mainText-head">
                                        一、面向群体
                                    </div>
                                    <div class="mainText-son-list">
                                        全国各省份初三至高三年级的学生均可报名参加。
                                    </div>
                                </div>
                                <div class="mainText-son">
                                    <div class="mainText-head">
                                        二、报名及考试时间安排
                                    </div>
                                    <div class="tablearea">
                                        <table cellspacing="0">
                                            <thead>
                                            <tr>
                                                <th>考试流程</th>
                                                <th>时间安排</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr class="striped">
                                                <td class="td_center">考试报名时间</td>
                                                <td>2021 年 7 月 26 日 10:00 至 8 月 16 日 24:00</td>
                                            </tr>
                                            <tr class="striped">
                                                <td rowspan="3" class="td_center">正式考试</td>
                                                <td>中英文阅读：2021 年 8 月 28 日 9:00-12:00</td>
                                            </tr>
                                            <tr class="striped">
                                                <td>物理：2021 年 8 月 28 日 13:30-15:00</td>
                                            </tr>
                                            <tr class="striped">
                                                <td>数学(丘成桐数学零试)：2021 年 8 月 28 日 15:15-17:15</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div class="mainText-son">
                                    <div class="mainText-son-list">
                                        *注：以上时间均指北京时间。
                                    </div>
                                </div>
                                <div class="mainText-son">
                                    <div class="mainText-head">
                                        三、考试形式
                                    </div>
                                    <div class="mainText-son-list">
                                        采用“线上机考”模式，考生在指定考点机房使用考试专用软件完成考试。
                                    </div>
                                </div>
                                <div class="mainText-son">
                                    <div class="mainText-head">
                                        四、成绩用途
                                    </div>
                                    <div class="mainText-son-list">
                                        参试考生可申请认证成绩单，认证成绩单可在申请丘成桐数学科学领军人才培养计划及丘成桐数学英才班等项目申报时使用。
                                    </div>
                                    <div class="mainText-son-list">
                                        其他部分采信认证成绩单的项目：
                                    </div>
                                    <div class="mainText-son-list">
                                        1、昆山杜克大学综合评价招生
                                    </div>
                                    <div class="mainText-son-list">
                                        2、香港中文大学（深圳）综合评价招生
                                    </div>
                                </div>
                            </div>
                        </div>-->
                        <!-- <div class="mainText">
                        <div>
                          <div class="mainText-son">
                            <div class="mainText-head">
                              一、面向群体
                            </div>
                            <div class="mainText-son-list">
                              考试面向全国各省份高一和高二年级学生，及特别优秀的初三及高三年级学生的水平测试。采取统一知识框
                              架及考试结构的考查方式，旨在识别学科综合能力优秀的学生。
                            </div>
                          </div>
                          <div class="mainText-son">
                            <div class="mainText-head">
                              二、考查内容
                            </div>
                            <div class="mainText-son-list">
                              1、中英文阅读以高一、高二知识为核心，考查中英文阅读及理解能力。
                            </div>
                            <div class="mainText-son-list">
                              2、数学、物理探究以高一、高二知识为核心，结合高中知识和大学选材视角考察基础知识的掌握和综合运
                              用能力，其中高一、高二知识约占 70%，大学知识约占 30%。
                            </div>
                            <div class="mainText-son-list">
                              3、数学科目分成数学一、数学二两部分，数学二可以选考。
                            </div>
                          </div>
                          <div class="mainText-son">
                            <div class="mainText-head">
                              三、时间安排
                            </div>
                            <div class="tablearea">
                              <table cellspacing="0">
                                <thead>
                                  <tr>
                                    <th>考试流程</th>
                                    <th>时间安排</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td class="td_center">考试报名截止时间</td>
                                    <td>2021 年 4 月 10 日 24:00</td>
                                  </tr>
                                  <tr class="striped">
                                    <td class="td_center">准考证下载/打印</td>
                                    <td>
                                      2021 年 4 月 21 日 8:00 至 2021 年 4 月 23 日 24:00
                                    </td>
                                  </tr>
                                  <tr>
                                    <td rowspan="4" class="td_center">正式考试</td>
                                    <td>中英文阅读：2021 年 4 月 24 日 9:00-12:00</td>
                                  </tr>
                                  <tr>
                                    <td>物理探究：2021 年 4 月 24 日 13:30-15:00</td>
                                  </tr>
                                  <tr>
                                    <td>数学一：2021 年 4 月 24 日 15:00-15:50</td>
                                  </tr>
                                  <tr>
                                    <td>
                                      数学二（选考）：2021 年 4 月 24 日 15:50-17:00
                                    </td>
                                  </tr>
                                  <tr class="striped">
                                    <td class="td_center">考试报告及认证成绩单获取</td>
                                    <td>2021 年 4 月 26 日 8:00 后</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                          <div class="mainText-son">
                            <div class="mainText-head">
                              四、考试形式
                            </div>
                            <div class="mainText-son-list">
                              采用“线上机考”模式，考生在指定考点机房使用考试专用软件完成考试。
                            </div>
                          </div>
                        </div>
                      </div>
                         <div class="tablearea">
                                <div
                                  class="el-table el-table--fit el-table--striped el-table--enable-row-hover el-table--enable-row-transition"
                                  style="width: 100%"
                                >
                                  <div class="hidden-columns">
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                  </div>
                                  <div class="el-table__header-wrapper">
                                    <table
                                      cellspacing="0"
                                      cellpadding="0"
                                      border="0"
                                      class="el-table__header"
                                    >
                                      <colgroup>
                                        <col name="el-table_12_column_50" width="215" />
                                        <col name="el-table_12_column_51" width="213" />
                                        <col name="el-table_12_column_52" width="213" />
                                        <col name="el-table_12_column_53" width="213" />
                                        <col name="el-table_12_column_54" width="213" />
                                        <col name="gutter" width="0" />
                                      </colgroup>
                                      <thead class="has-gutter">
                                        <tr class="">
                                          <th
                                            colspan="1"
                                            rowspan="1"
                                            class="el-table_12_column_50 is-center is-leaf"
                                          >
                                            <div class="cell">日期</div>
                                          </th>
                                          <th
                                            colspan="1"
                                            rowspan="1"
                                            class="el-table_12_column_51 is-center is-leaf"
                                          >
                                            <div class="cell">时间</div>
                                          </th>
                                          <th
                                            colspan="1"
                                            rowspan="1"
                                            class="el-table_12_column_52 is-center is-leaf"
                                          >
                                            <div class="cell">科目</div>
                                          </th>
                                          <th
                                            colspan="1"
                                            rowspan="1"
                                            class="el-table_12_column_53 is-center is-leaf"
                                          >
                                            <div class="cell">时长</div>
                                          </th>
                                          <th
                                            colspan="1"
                                            rowspan="1"
                                            class="el-table_12_column_54 is-center is-leaf"
                                          >
                                            <div class="cell">满分</div>
                                          </th>
                                          <th
                                            class="gutter"
                                            style="width: 0px; display: none"
                                          ></th>
                                        </tr>
                                      </thead>
                                    </table>
                                  </div>
                                  <div class="el-table__body-wrapper is-scrolling-none">
                                    <table
                                      cellspacing="0"
                                      cellpadding="0"
                                      border="0"
                                      class="el-table__body"
                                    >
                                      <colgroup>
                                        <col name="el-table_12_column_50" width="215" />
                                        <col name="el-table_12_column_51" width="213" />
                                        <col name="el-table_12_column_52" width="213" />
                                        <col name="el-table_12_column_53" width="213" />
                                        <col name="el-table_12_column_54" width="213" />
                                      </colgroup>
                                      <tbody>
                                        <tr class="el-table__row">
                                          <td
                                            rowspan="1"
                                            colspan="1"
                                            class="el-table_12_column_50 is-center"
                                          >
                                            <div class="cell">4月24日</div>
                                          </td>
                                          <td
                                            rowspan="1"
                                            colspan="1"
                                            class="el-table_12_column_51 is-center"
                                          >
                                            <div class="cell">9:00-12:00</div>
                                          </td>
                                          <td
                                            rowspan="1"
                                            colspan="1"
                                            class="el-table_12_column_52 is-center"
                                          >
                                            <div class="cell">中英文阅读</div>
                                          </td>
                                          <td
                                            rowspan="1"
                                            colspan="1"
                                            class="el-table_12_column_53 is-center"
                                          >
                                            <div class="cell">180min</div>
                                          </td>
                                          <td
                                            rowspan="1"
                                            colspan="1"
                                            class="el-table_12_column_54 is-center"
                                          >
                                            <div class="cell">200分</div>
                                          </td>
                                        </tr>
                                        <tr class="el-table__row el-table__row--striped">
                                          <td
                                            rowspan="1"
                                            colspan="1"
                                            class="el-table_12_column_50 is-center"
                                          >
                                            <div class="cell">4月24日</div>
                                          </td>
                                          <td
                                            rowspan="1"
                                            colspan="1"
                                            class="el-table_12_column_51 is-center"
                                          >
                                            <div class="cell">14:00-17:00</div>
                                          </td>
                                          <td
                                            rowspan="1"
                                            colspan="1"
                                            class="el-table_12_column_52 is-center"
                                          >
                                            <div class="cell">数理探究</div>
                                          </td>
                                          <td
                                            rowspan="1"
                                            colspan="1"
                                            class="el-table_12_column_53 is-center"
                                          >
                                            <div class="cell">180min</div>
                                          </td>
                                          <td
                                            rowspan="1"
                                            colspan="1"
                                            class="el-table_12_column_54 is-center"
                                          >
                                            <div class="cell">200分</div>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                  <div
                                    class="el-table__column-resize-proxy"
                                    style="display: none"
                                  ></div>
                                </div>
                              </div> -->
                    </div>
                </div>
            </div>
        </el-main>
    </div>
</template>

<script>
    import {ExamDetail} from "r/index/examlist";
    // import {signUpInit} from "r/index/signUp";
    import {signUpInitReq} from "common/request";

    export default {
        data() {
            return {
                isDisable: false, // 禁用 false-不禁用，true-禁用
                examuuid: "",
                examInfo: {}, // 考试须知
                tableData: [
                    {
                        data: "4月24日",
                        time: "9:00-12:00",
                        subject: "中英文阅读",
                        timelong: "180min",
                        score: "200分",
                    },
                    {
                        data: "4月24日",
                        time: "14:00-17:00",
                        subject: "数理探究",
                        timelong: "180min",
                        score: "200分",
                    },
                ],
            };
        },
        created() {
            this.examuuid = window.localStorage.getItem("exam_uuid");
            this.examdetail();
        },
        methods: {
            //考试须知信息
            async examdetail() {
                const {data: res} = await ExamDetail({exam_uuid: this.examuuid});
                console.log(res, "返回考试");
                if (res.code != 200) return this.$message.error(res.message);
                this.examInfo = res.info;
            },
            async toidentity() {
                if (this.isDisable) {
                    return false;
                }
                this.isDisable = true;
                //初始化报名信息
                await signUpInitReq(this.examuuid);
                this.isDisable = false;
                // const {data: res} = await signUpInit({exam_uuid: this.examuuid});
                // console.log(res, "报名初始化完成");
                // if (res.code !== 200) {
                //     if (res.code == 4002139) {
                //         await this.$router.replace({
                //             name: "StepOne",
                //             query: {applicationstatus: 0},
                //         });
                //         return this.$message.info(res.message);
                //     } else if (res.code == 4002131) {
                //         await this.$router.replace({name: "OrderList"});
                //         return this.$message.info(res.message);
                //     }
                //     return this.$message.error(res.message);
                // }
                // window.localStorage.setItem("exam_uuid", res.exam_uuid);
                // window.localStorage.setItem("order_no", res.order_no);
                // this.isDisable = false;
                // if (res.status == 1) {
                //     console.log("报名-填写邀请码");
                //     await this.$router.push({name: "invatation",});
                // } else if (res.status == 2) {
                //     // 跳过邀请码
                //     console.log("报名-选择考点城市");
                //     await this.$router.push({name: "SignUpLists"});
                // } else if (res.status == 3) {
                //     // 绑定邀请码
                //     console.log("报名-选择考点城市");
                //     await this.$router.push({name: "SignUpLists"});
                // } else if (res.status == 4) {
                //     console.log("报名-确认报名信息");
                //     await this.$router.push({name: "ConfirmInfo"});
                // } else if (res.status == 5) {
                //     console.log("报名-待支付状态");
                //     await this.$router.push({name: "ConfirmInfo"});
                // } else if (res.status == 7) {
                //     console.log("报名-订单超时");
                //     await this.$router.push({name: "SignUpLists"});
                // } else if (res.status == 8) {
                //     console.log("报名-取消报名");
                //     await this.$router.push({name: "invatation"});
                // }
            },
        },
    };
</script>

<style scoped lang="scss">
    // 面包屑样式
    @import "a/scss/beradcrumb";
    // 表格样式
    @import "a/scss/table";

    #main {
        width: 100%;
        min-height: 80%;
        overflow: auto;
        background-color: #fff;
        padding-bottom: 30px;

        .header {
            width: 100%;
            height: 160px;
            // margin: 6px auto;
            background-color: #f5f5f5;

            .box {
                max-width: 1300px;
                padding: 0 50px;
                margin: 0 auto;
                display: block;

                .header_txt {
                    font-weight: 400;
                    font-size: 24px;
                    padding: 45px 0 0;
                }
            }
        }

        .el-main {
            max-width: 1300px;
            padding: 0 50px;
            margin: 0 auto;

            .section {
                width: 100%;
                background: #fff;
                margin: 20px 0 0;
                padding: 0;

                .header-top {
                    height: 40px;
                    .btn_1{
                        color: #ffffff;
                        border-color: #CCCCCC;
                        background-color: #CCCCCC;
                    }
                    .btn_2{
                        color: #ffffff;
                        border-color: #563279;
                        background-color: #563279;
                    }
                }

                .textBox {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    .handle {
                        height: 30px;
                        font-size: 30px;
                        font-family: PingFang SC;
                        font-weight: 400;
                        color: #000000;
                        line-height: 30px;
                        margin: 0 0 40px 0;
                    }

                    .headBox {
                        width: 100%;
                        line-height: 30px;
                        padding: 30px 22px;
                        background-color: #f5f5f5;
                        border: none;
                        border-radius: 5px;
                        // text-indent: 2em;
                    }
                }
            }
        }
    }

    ::v-deep .el-breadcrumb__item:last-child .el-breadcrumb__inner {
        color: #56327a;
    }

    ::v-deep .el-button {
        background: #56327a;
        color: #fff;
        margin-left: 85%;
    }
</style>
<style lang="scss">
    .mainText {
        width: 100%;
        padding: 20px 0;

        .mainText-son {
            margin: 20px;

            .mainText-head {
                font-weight: bold;
                margin-bottom: 10px;
            }

            .mainText-son-list {
                margin-top: 5px;
                /*text-indent: 2em;*/
                line-height: 25px;

                li {
                    text-indent: 0;
                    list-style: disc;
                    margin-left: 3em;
                }
            }
        }

        .mainText-list {
            .mainText-list-li {
                margin-top: 10px;
                list-style-type: disc;
                margin-left: 13px;
            }
        }

        .tablearea {
            padding: 1em 2em;

            table {
                width: 100%;
                border-bottom: 1px solid #ebeef5;
                font-size: 14px;
                font-family: PingFang SC;
                font-weight: 400;
                color: #000000;

                th,
                td {
                    height: 54px;
                    text-align: center;
                    // border-radius: 20px;
                }

                th {
                    background-color: #f5f5f5;
                }

                tr.striped {
                    background-color: #FdFdFd;
                }

                .td_center {
                    width: 40%;
                    text-align: center;
                }

                // tr:nth-child(even) {
                //   background-color: #f5f5f5;
                // }
                tr {
                    th:first-child,
                    td:first-child {
                        border-radius: 4px 0 0 4px;
                    }

                    th:last-child,
                    td:last-child {
                        border-radius: 0 4px 4px 0;
                    }

                    /*td:last-child {*/
                    /*    padding-left: 30px;*/
                    /*}*/
                }
            }
        }
    }
</style>
